import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as SignalR from '@microsoft/signalr';
import { toastr } from 'react-redux-toastr';
import ReactTooltip from 'react-tooltip';
import { isEmpty, find, get } from 'lodash';
import moment from 'moment-timezone';
import jstz from 'jstz';
import {
  getCoreMetaDetails,
  loadARITerms,
  loadEvents,
  loadGeocode,
  loadMarketplaceFeatures,
  loadMarketplaces,
  loadSimulcastStatus,
  loadSpinCars,
  loadUserProfile,
  loadUserSelectedLocation,
  loadWatchlistObject,
  loadWishlistItems,
  resetBidMessageHistoryRealTime,
  resetBidMessagesRealTime,
  setListingStatusIds,
  setOnlineConnected,
  setCustomerPlatformLink,
  setCustomerTermsLink,
  setRealTimeConnected,
  setTemplate,
  updateBidMessageHistoryRealTime,
  updateBidMessagesRealTime,
} from '../actions';
import {
  clearAllStorage,
  findStateAbbreviation,
  getConfig,
  getParamFromUrl,
  getTemplate,
  hasMPFeature,
  isJSON,
  checkLink,
  makeAssetLink,
  makeLiveSimulcastLink,
  makeTemplate,
  makeTitle,
} from '../utils/helpers';
import { mpBrowseLinksMap, breadcrumbLabels, urlsMap, externalAssetNames } from '../utils/constants';
import NotificationsDropdown from '../components/NotificationsDropdown';
import IASConnectModal from './IASConnectModal';
import TermsAndConditionsModal from './TermsAndConditionsModal';
import { slide as Menu } from 'react-burger-menu';
import { addStratosLoaderScript } from '../utils/widgets';

const timezone = jstz.determine().name();

const styles = {
  fluidContainer: {
    position: 'fixed',
    width: '100%',
  },
  hr: {
    padding: 0,
    marginTop: -5,
    marginBottom: 5,
    marginRight: 20,
    opacity: 0.5,
  },
  bmBurgerButton: {
    position: 'relative',
    width: '30px',
    height: '26px',
    float: 'right',
    marginTop: '15px',
  },
  bmBurgerBars: {
    background: '#ccc',
    height: '15%',
  },
  bmCrossButton: {
    height: '24px',
    width: '24px',
  },
  bmCross: {
    background: '#bdc3c7',
  },
  bmMenu: {
    background: '#373a47',
    padding: '2.5em 1.5em 0',
    fontSize: '1.25em',
    paddingBottom: 22,
  },
  bmMorphShape: {
    fill: '#373a47',
  },
  bmItemList: {
    color: '#b8b7ad',
    padding: '1.3em',
    overflow: 'auto',
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.0)',
  },
  realTimeIndicator: {
    display: 'block',
    float: 'left',
    width: 8,
    height: 8,
    marginTop: 5,
    borderRadius: '100%',
  },
  subNav: {
    height: 54,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#fafafa',
    padding: '15px 30px 15px 20px',
    borderBottom: `1px solid #D0D0D0`,
  },
  breadcrumbItem: {
    fontSize: 16,
    maxWidth: 250,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'flex',
  },
};

class TopNav extends Component {
  constructor(props) {
    super(props);

    const includeNoSales = false; // until we make it configurable
    props.setListingStatusIds(includeNoSales ? [1, 3, 5] : [1, 3]);

    this.state = {
      includeNoSales,
      browseLinks: [],
      query: getParamFromUrl('q', props.location.search),
      menuOpen: false,
      loadingUser: false,
      iasConnectUrl: '',
      iasConnectModalOpen: false,
      isTermsModalOpen:
        getConfig('agreeToTermsAtLogin') &&
        sessionStorage.getItem('agreedToTerms') !== 'true',
    };
  }

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps) {
    this.loadUserLocation();
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.handleBreadcrumbs();
    }
    if (
      !prevProps.marketplaceFeatures.features &&
      this.props.marketplaceFeatures.features
    ) {
      if (hasMPFeature(this.props.marketplaceFeatures.features, 546)) {
        this.props.loadSpinCars();
      }
      if (getConfig('agreeToTermsAtLogin')) {
        if (
          !this.props.location.pathname.includes('/info/') &&
          sessionStorage.getItem('agreedToTerms') !== 'true'
        ) {
          this.setState({ isTermsModalOpen: true });
        }
      }
    }
    if (
      this.props.location.pathname !== prevProps.location.pathname ||
      (this.props.userProfile.user && !this.props.userProfile.user.accountId)
    ) {
      this.handleMenu({ isOpen: false });
    }
    this.checkForReconnect(prevProps);
    // TODO - test as it is refactored from componentWillReceiveProps
    if (
      this.props.simulcastStatus.simulcastStatus !==
      prevProps.simulcastStatus.simulcastStatus
    ) {
      const liveEventsNum =
        this.props.simulcastStatus.simulcastStatus.eventsRunning;
      if (liveEventsNum > 0) {
        let indicatorSelector = document.querySelector(
          '#live-simulcast-indicator'
        );
        if (indicatorSelector && indicatorSelector.style) {
          indicatorSelector.style.display = 'block';
        }
      }
    }
    if (
      // when leaving search restore
      prevProps.location?.pathname === '/search' &&
      this.props.location?.pathname !== '/search'
    ) {
      this.props.setListingStatusIds(
        this.state.includeNoSales ? [1, 3, 5] : [1, 3]
      );
    }
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleMessage);
    clearInterval(this.timer);
  }

  init = async () => {
    const mpId = getConfig('marketplaceId');

    this.props.loadUserProfile(mpId);
    this.props.loadEvents(mpId);
    this.handleBreadcrumbs();
    this.checkConnectivity();
    this.props.loadSimulcastStatus(mpId);
    this.props.loadWatchlistObject(mpId);
    this.props.loadMarketplaceFeatures(mpId);
    this.props.loadWishlistItems(mpId);
    this.props.loadMarketplaces(mpId).then(({ response }) => {
      if (response.wsStatus === 'Success') {
        const { marketplaceList = [] } = response;
        const parentMarketplace = find(
          marketplaceList,
          mp => mp.marketplaceId == mpId
        );
        if (parentMarketplace) {
          this.loadTemplate(parentMarketplace);
          this.loadBrowseLinks(parentMarketplace);
        }
      }
    });
    this.connectSignalR({
      ...this.props,
      setRealTimeConnected: this.setRealTimeConnected,
    });
    this.loadPlatformLink();
    this.loadTermsLink();
    if (this.state.isTermsModalOpen) {
      this.props.loadARITerms();
    }

    window.addEventListener('message', this.handleMessage);
    /* * TEMP ** Only enable in VDP */
    /* if (window.top === window.parent) {
      this.initDarwinBookout();
    } */
  };

  /* Assign user values to FreshChat widget */
  initSupportWidgetUser = () => {
    if (getConfig('hideSiaChatWidget')) {
      return;
    };
    
    try {
      const {firstName, lastName, email, userId: externalId} = this.props.userProfile?.user;
      
      if (window.fcWidget && window.fcWidget.isInitialized()) {
        let lastInitial = lastName.charAt(0);
        
        window.fcWidget.user.setProperties({
          firstName,
          lastName: lastInitial,
          email,
          externalId,
        });
      }

    } catch (initChatUserError) {
      console.error(initChatUserError);
    }
  }

  // Darwin Bookout | StratosLoader - enables 'Vehicle Values' button in VDP & Search
  initDarwinBookout = () => {
    const stratosConfig = getConfig("stratosConfig");
    if (stratosConfig) {
      addStratosLoaderScript(stratosConfig);
    }
  }

  handleBreadcrumbs = () => {
    // NOTE - All pages have subnav with breadcrumbs except Home page
    // The Home link is always shown in breadcrumbs UI so no need to store it.
    // VDP is always the last breadcrumb
    // most of the breadcrumbs are handled HERE. Exceptions include Search page, ItemsPage, and Table Pagination,
    const { location } = this.props;
    const isVDP = location.pathname.includes('/item/');
    const isSearch = location.pathname.includes('/search');

    if (!isVDP && !isSearch) {
      sessionStorage.removeItem('searchScroll');
    }

    // On Home page so reset the breadcrumbs
    if (location.pathname === '/') {
      sessionStorage.setItem(
        'bcCrumbs',
        JSON.stringify({ path: location.pathname, crumbs: [] })
      );
      sessionStorage.removeItem('navItems'); // not technically breadcrumbs but doing it here anyway
    } else {
      // on any page other than the Home page
      const bcCrumbs = sessionStorage.getItem('bcCrumbs');
      const existingBreadcrumbs = bcCrumbs
        ? JSON.parse(bcCrumbs)
        : { path: location.pathname, crumbs: [] };

      const newBreadcrumbs = { path: location.pathname, crumbs: [] };
      // if VDP and coming from a page other than the Home page keep the page before
      newBreadcrumbs.crumbs =
        isVDP &&
        existingBreadcrumbs.crumbs.length &&
        !existingBreadcrumbs.crumbs[0].link.includes('/item/')
          ? [existingBreadcrumbs.crumbs[0]]
          : [];

      const text =
        breadcrumbLabels[
          (location.pathname || '').toLowerCase().replace(/\d+/g, '')
        ] ||
        get(location, 'state.routeName') ||
        makeTitle(location.pathname);

      const link = location.pathname + location.search;
      const newCrumb = { text, link };

      // add the current crumb
      newBreadcrumbs.crumbs = [...newBreadcrumbs.crumbs, newCrumb];

      // set the breadcrumbs
      sessionStorage.setItem('bcCrumbs', JSON.stringify(newBreadcrumbs));
    }
  };

  checkForReconnect(prevProps) {
    if (
      (prevProps.onlineConnected === false &&
        this.props.onlineConnected === true) ||
      (prevProps.realTimeConnected === false &&
        this.props.realTimeConnected === true)
    ) {
      this.props.resetBidMessagesRealTime();
      this.props.resetBidMessageHistoryRealTime();
      this.init(); // do we need to do this? safer, but necessary?
    }
  }

  checkConnectivity() {
    this.setConnected();
    window.addEventListener('online', this.setConnected);
    window.addEventListener('offline', this.setConnected);
  }

  connectSignalR = props => {
    const apiKey = props.cookies.get('apiKey');
    const mpId = getConfig('marketplaceId');
    const realTimeUrl = getConfig('realTimeUrl');

    if (!realTimeUrl || this.props.realTimeConnected) return null;

    const connection = new SignalR.HubConnectionBuilder()
      .withUrl(realTimeUrl)
      .configureLogging(SignalR.LogLevel.Debug)
      .build();

    async function start() {
      // realtime connection
      await connection
        .start()
        .then(() => {
          props.setRealTimeConnected(true);

          // BidMessage invocation
          connection
            .invoke('BidMessage', {
              apiKey,
              mpId: Number(mpId),
              subscribe: 1,
            })
            .then(() => {
              connection.on('BidMessage', data => {
                props.updateBidMessagesRealTime(data);
              });
            })
            .catch(err => {
              console.error(
                `### SignalR invocation catch error for BidMessage`,
                JSON.stringify(err)
              );
              props.setRealTimeConnected(false, 'INVOKE: BidMessage');
            });

          // BidMessageHistory invocation
          connection
            .invoke('BidMessageHistory', {
              apiKey,
              mpId: Number(mpId),
              subscribe: 1,
            })
            .then(() => {
              connection.on('BidMessageHistory', data => {
                const { pathname } = props.location;
                const onVDP = pathname.includes('/item/');
                if (onVDP) props.updateBidMessageHistoryRealTime(data);
              });
            })
            .catch(err => {
              console.error(
                `### SignalR invocation catch error for BidMessageHistory`,
                JSON.stringify(err)
              );
              props.setRealTimeConnected(false, 'INVOKE: BidMessageHistory');
            });
        })
        .catch(err => {
          console.error(
            `### SignalR connection catch error`,
            JSON.stringify(err)
          );
          props.setRealTimeConnected(false, 'CONNECT');
          // if doesn't connect try again
          setTimeout(start, Math.random() * (5000 - 2000) + 2000);
        });
    }

    connection.onclose(async () => {
      props.setRealTimeConnected(false, 'CLOSE');
      // if gets disconnected try to connect again
      setTimeout(start, Math.random() * (5000 - 2000) + 2000);
    });

    start();
  };

  /**
   * @async
   * @returns {void}
   * @description Sets Redux `state.entities.customerPlatformLink`. Checks
   *  if `platform_terms_of_use.pdf` exists in customer s3 bucket. 
   * * Defaults to: `urlsMap['PLATFORM_TERMS_OF_USE']`
   */
  loadPlatformLink = async () => {
    if (!this.props.customerPlatformLink) {
      try {
        const platformTermsUrl = makeAssetLink(externalAssetNames.PLATFORM_TERMS_OF_USE);
        const customerTermsExists = await checkLink(platformTermsUrl);
        const customerPlatformLink = customerTermsExists 
          ? platformTermsUrl
          : urlsMap.get('PLATFORM_TERMS_OF_USE');
        this.props.setCustomerPlatformLink(customerPlatformLink);
      } catch (loadPlatLinkErr) {
        console.error(loadPlatLinkErr)
      }
    }
  }

  loadTermsLink = async () => {
    // if the customer does not have a terms link in their config we hide the link
    // if the customer does have a terms link in their config we check to see if it is valid
    // if the link is not valid we link them to the contact page
    const termsLink = getConfig('termsLink');
    const customerTermsLink = termsLink
      ? (await checkLink(termsLink))
        ? termsLink
        : '/info/contact-us'
      : null;

    this.props.setCustomerTermsLink(customerTermsLink);
  };

  handleMessage = e => {
    if (isJSON(e.data)) {
      const postMessage = JSON.parse(e.data);
      if (postMessage.channel === 'ias-connect') {
        if (postMessage.redirectURI) {
          window.location = postMessage.redirectURI;
        }
        if (postMessage.message === 'LOGOUT') {
          clearAllStorage();
          this.props.navigate('/login');
        }
        this.setState({ iasConnectModalOpen: false, iasConnectUrl: '' });
      }
    }
  };

  loadUserLocation = () => {
    // aim here is to put userSelectedLocation obj in the store with an address and coordinates
    if (
      isEmpty(this.props.userSelectedLocation) &&
      this.props.userProfile.user
    ) {
      const { city, state, zipcode, latitude, longitude } =
        this.props.userProfile.user;

      const address = `${city || ''} ${state || ''} ${zipcode || ''}`.trim();
      const hasCoordinates =
        latitude && latitude !== '0' && longitude && longitude !== '0';

      if (!address && !hasCoordinates) {
        // unable to get user location ¯\_(ツ)_/¯
        return null;
      }

      if (address && !hasCoordinates && !this.state.loadingGeoCode) {
        // if we have an address (zip || city || state) we can fetch the coordinates
        this.setState({ loadingGeoCode: true }, () => {
          const mpId = getConfig('marketplaceId');
          this.props
            .loadGeocode(mpId, address)
            .then(({ response }) => {
              if (response && response.wsStatus === 'Success') {
                this.props.loadUserSelectedLocation({
                  key: 'user',
                  address,
                  city,
                  state: findStateAbbreviation(state),
                  zipcode,
                  location: {
                    lat: Number(response.data.lat),
                    lng: Number(response.data.lng),
                  },
                });
              }

              this.setState({ loadingGeoCode: false });
            })
            .catch(error => console.error(error));
        });
      } else if (address && hasCoordinates) {
        // if we have the address and coordinates we're good to go
        this.props.loadUserSelectedLocation({
          key: 'user',
          address,
          city,
          state: findStateAbbreviation(state),
          zipcode,
          location: {
            lat: Number(latitude),
            lng: Number(longitude),
          },
        });
      }
    }
  };

  loadTemplate = (parentMarketplace = {}) => {
    // this should have been done in Login.js but we do it again here to be sure.
    const cssConfig = get(parentMarketplace, 'cssConfig') || {};
    const template = makeTemplate(cssConfig);
    this.props.setTemplate(template); // puts it in the store
  };

  loadBrowseLinks = (parentMarketplace = {}) => {
    // no awg settings for this and not in the configs so keep here for now
    const mpBrowseLinks = '3,4,1,8,9,16,7,99';

    const browseLinks = mpBrowseLinks
      .split(',')
      .map(key => {
        const item = mpBrowseLinksMap.get(key.trim()) || '';
        if (!item) {
          console.error(`mpBrowseLinksMap does not contain key ${key}`);
        }
        return item;
      })
      .filter(val => val)
      .filter(item => {
        const hasLink = item.link;
        let isEnabled = item.configKey ? getConfig(item.configKey) : true;
        // one off. noSalesEnabled = false if marketplace feature "106" is present on parent marketplace
        const noSalesDisabled = (
          parentMarketplace.marketplaceFeaturesArray || []
        ).includes('106');
        if (item.name === 'NoSales' && noSalesDisabled) {
          isEnabled = false;
        }

        return hasLink && isEnabled;
      });

    this.setState({ browseLinks });
  };

  setConnected = (e = {}) => {
    const connected = window.navigator.onLine;
    const { pathname } = this.props.location;

    if (connected !== this.props.onlineConnected && pathname !== '/login') {
      // if reconnected after disconnection
      if (this.props.onlineConnected === false && connected) {
        toastr.clean();
        toastr.success('Success', 'Back Online', {
          timeOut: 2000,
        });
      }

      this.props.setOnlineConnected(connected);

      // if not connected start the timer
      // at end of timer if still not connected notify user
      if (!connected) {
        setTimeout(() => {
          if (!window.navigator.onLine) {
            toastr.error('Alert', 'Connection Error', {
              timeOut: 0,
            });
          }
        }, 5000);
      }
    }
  };

  setRealTimeConnected = (realTimeConnected, info = '') => {
    if (this.props.realTimeConnected !== realTimeConnected) {
      // if reconnected after disconnection
      if (this.props.realTimeConnected === false && realTimeConnected) {
        toastr.clean();
        toastr.success('Success', 'RealTime Reconnected', {
          timeOut: 2000,
        });
      }

      this.props.setRealTimeConnected(realTimeConnected);

      // if not connected start the timer
      // at end of timer if still not connected AND internet connection is good notify user
      if (realTimeConnected === false) {
        setTimeout(() => {
          if (!this.props.realTimeConnected) {
            // "extra" just lets the devs know what the underlying problem was
            const extra =
              info === 'INVOKE: BidMessageHistory'
                ? '.'
                : info === 'INVOKE: BidMessage'
                ? '..'
                : info === 'CONNECT'
                ? '...'
                : '';

            toastr.warning('Alert', `RealTime Connection Error${extra}`, {
              timeOut: 0,
            });
          }
        }, 5000);
      }
    }
  };

  handleClick = e => {
    e.preventDefault();

    // if (this.props.location.pathname === '/search') {
    //   this.setState({ query: '' });
    // }

    const url = e.target.getAttribute('href') || '/';
    this.props.navigate(url);
  };

  handleSignout = e => {
    if (e) e.preventDefault();
    const iasConnectRoot = getConfig('iasConnectRoot');
    const iasConnectUrl = `${iasConnectRoot}/account/logout`;
    this.setState({ iasConnectModalOpen: true, iasConnectUrl });
  };

  handleTermsClick = agree => {
    if (agree) {
      sessionStorage.setItem('agreedToTerms', true);
      this.setState({ isTermsModalOpen: false });
    } else {
      this.setState({ isTermsModalOpen: false }, () => {
        this.handleSignout();
      });
    }
  };

  openWindow = (
    url,
    cache,
    center,
    scroll,
    left,
    top,
    width,
    height,
    advancedFeatures
  ) => {
    if (cache === 0) {
      const d = moment.tz(timezone).format();
      if (url.indexOf('?') === -1) url = `${url}?pageversion=${d.getTime()}`;
      else url = `${url}&pageversion=${d.getTime()}`;
    }
    if (center === 1) {
      left = (window.screen.availWidth - width) / 2;
      top = (window.screen.availHeight - height) / 2;
    }

    let features =
      `toolbar=0,scrollbars=${scroll},left=${left},` +
      `top=${top},height=${height},width=${width}`;
    if (advancedFeatures !== '') {
      features = `${features},${advancedFeatures}`;
    }
    window.open(url, '', features);
  };

  renderMenuHR() {
    return <hr style={styles.hr} />;
  }

  renderNavHeader(isHome) {
    const linkColor = isHome ? '#fff' : '#777';
    const headerLogo = isHome
      ? makeAssetLink('headerLogo.png')
      : makeAssetLink('headerLogoDark.png') || makeAssetLink('headerLogo.png');

    return (
      <Link
        to="/"
        state={{ routeName: 'Home' }}
        className="pull-left "
        style={{
          display: 'flex',
          alignItems: 'center',
          height: '100%',
          paddingTop: 8,
          paddingBottom: 8,
          marginRight: 24,
        }}
      >
        <img
          style={{ height: 40, marginRight: 10, paddingTop: 5 }}
          src={headerLogo}
          role="presentation"
          alt="home logo"
        />
        <div style={{ color: linkColor, fontSize: 18 }}>
          {getConfig('homeLabel') || 'Marketplace'}
        </div>
      </Link>
    );
  }

  renderProfileLinks(linkColor) {
    return (
      <li className="dropdown">
        <Link
          to="#"
          className="dropdown-toggle"
          style={{ color: linkColor }}
          data-toggle="dropdown"
          role="button"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {get(this.props.userProfile, 'user.firstName', '')}
          <span
            className={`fa fa-cog ${this.state.loadingUser ? 'fa-spin' : ''}`}
          />
        </Link>
        <ul className="dropdown-menu" style={{ left: -80 }}>
          <li>
            <Link to="/profile" state={{ routeName: 'Profile' }}>
              Profile
            </Link>
          </li>
          <li>
            <Link
              to="/logout"
              state={{ routeName: 'Logout' }}
              onClick={this.handleSignout}
            >
              Sign Out
            </Link>
          </li>
        </ul>
      </li>
    );
  }

  renderHelpLinks(linkColor) {
    const { customerTermsLink } = this.props;

    return (
      <li className="dropdown">
        <Link
          to="#"
          className="dropdown-toggle step-help"
          style={{ color: linkColor }}
          data-toggle="dropdown"
          role="button"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Help
          <span className="fa fa-question-circle" style={{ marginLeft: 4 }} />
        </Link>

        <ul className="dropdown-menu">
          {getConfig('supportLink') !== null && (
            <li>
              <a
                href={getConfig('supportLink') || '/info/contact-us'}
                target="_blank"
                rel="noreferrer"
              >
                Contact Us
              </a>
            </li>
          )}
          {getConfig('faqLink') !== null && (
            <li>
              <a
                href={getConfig('faqLink') || '/info/faq'}
                target="_blank"
                rel="noreferrer"
              >
                FAQ
              </a>
            </li>
          )}
          <li>
            <a
              href={getConfig('privacyLink') || urlsMap.get('PLATFORM_PRIVACY_POLICY')}
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </li>
          {customerTermsLink && (
            <li>
              <a href={customerTermsLink} target="_blank" rel="noreferrer">
                {`Terms & Conditions`}
              </a>
            </li>
          )}
        </ul>
      </li>
    );
  }

  renderLiveSimulcastLink(linkColor) {
    const simulcastEnabled = hasMPFeature(
      this.props.marketplaceFeatures.features,
      410
    );

    if (simulcastEnabled) {
      return (
        <li>
          <a
            href={makeLiveSimulcastLink(this.props.cookies)}
            target="_blank"
            style={{ color: linkColor }}
            rel="noreferrer"
          >
            <span
              id="live-simulcast-indicator"
              className="glow"
              style={{ display: 'none' }}
            />{' '}
            Live Simulcast
          </a>
        </li>
      );
    }
  }

  renderRunlists(linkColor) {
    const { events, unauthorizedEventIds } = this.props;
    const { browseLinks } = this.state;

    if (!browseLinks.length) {
      return null;
    }

    const runlist = (events.eventList || [])
      .filter(event => {
        const isAllowed = !unauthorizedEventIds.includes(event.eventId);
        const hasItemCount = event.itemCountAtAuction > 0;
        const isCorrectType = [1, 3].includes(event.eventTypeId);
        return isAllowed && hasItemCount && isCorrectType;
      })
      .sort((a, b) => {
        if (a.eventShortName < b.eventShortName) return -1;
        else if (a.eventShortName > b.eventShortName) return 1;
        else {
          if (a.eventStartTime < b.eventStartTime) return -1;
          else if (a.eventStartTime > b.eventStartTime) return 1;
          else return 0;
        }
      })
      .map(item => {
        const searchLink = `/search?eventName[0]=${encodeURIComponent(
          item.eventName
        )}`;
        const runlistLink = `/runlist/${item.eventId}`;

        return (
          <li key={item.eventId}>
            <Link
              to={item.itemCountAtAuction > 1000 ? searchLink : runlistLink}
              state={{ routeName: `Runlist ${item.eventId}` }}
            >
              {item.eventName}
            </Link>
          </li>
        );
      });

    if (browseLinks.map(item => item.name).includes('NoSales')) {
      runlist.push(
        <li key="noSales">
          <Link
            to={`/runlist/nosales`}
            state={{ routeName: `Runlist No-Sales` }}
          >
            No-Sales
          </Link>
        </li>
      );
    }

    return (
      <li className="dropdown">
        <Link
          to="#"
          style={{ color: linkColor }}
          className="dropdown-toggle"
          data-toggle="dropdown"
          role="button"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Runlists <span className="caret" />
        </Link>
        <ul className="dropdown-menu">
          {runlist && runlist.length ? runlist : <li>No Runlists Available</li>}
        </ul>
      </li>
    );
  }

  renderConnectionIndicator(linkColor, isHome) {
    // can be used for any customer, but atm only enabled for signalR customers
    if (!getConfig('realTimeUrl')) return null;

    const { onlineConnected, realTimeConnected } = this.props;

    const status = getConfig('realTimeUrl')
      ? onlineConnected && realTimeConnected
        ? 'connected'
        : onlineConnected && !realTimeConnected
        ? 'realtime not connected'
        : 'not connected'
      : onlineConnected
      ? 'connected'
      : 'not connected';

    const backgroundColor =
      status === 'connected'
        ? '#27AE60'
        : status === 'not connected'
        ? '#C0392B'
        : status === 'realtime not connected'
        ? '#F39C12'
        : null;

    const shadowColor = isHome ? `#696969` : '#D3D3D3';

    return (
      <li>
        <ReactTooltip id="connection" />
        <Link to="#" style={{ color: linkColor, cursor: 'pointer' }}>
          <span
            data-for="connection"
            data-tip={status}
            style={{
              ...styles.realTimeIndicator,
              backgroundColor,
              boxShadow: `inset 0px 0px 20px 2px ${backgroundColor}, 0px 0px 20px 2px ${shadowColor}`,
            }}
          />
        </Link>
      </li>
    );
  }

  renderSearchLinkDesktop(linkColor) {
    return (
      <li style={{ color: linkColor }}>
        <Link
          to="/search"
          state={{ routeName: 'Search' }}
          style={{ color: linkColor }}
        >
          Search
        </Link>
      </li>
    );
  }

  renderMyActivityLinksDesktop(linkColor) {
    return (
      <li className="dropdown">
        <Link
          to="#"
          className="dropdown-toggle"
          style={{ color: linkColor }}
          data-toggle="dropdown"
          role="button"
          aria-haspopup="true"
          aria-expanded="false"
        >
          My Activity <span className="caret" />
        </Link>
        <ul className="dropdown-menu">
          <li>
            <Link to="/myactivity" state={{ routeName: 'My Activity' }}>
              Activity Timeline
            </Link>
          </li>
          {!getConfig('disableBuyerActivity') && (
            <li>
              <Link to="/status" state={{ routeName: 'Status' }}>
                Buyer
              </Link>
            </li>
          )}
          {!getConfig('disableSellerActivity') && (
            <li>
              <Link to="/inventory" state={{ routeName: 'Inventory' }}>
                Seller
              </Link>
            </li>
          )}

          {getConfig('enableGatePass') && (
            <li>
              <Link to="/gatepass" state={{ routeName: 'Gate Pass' }}>
                Gate Passes
              </Link>
            </li>
          )}
        </ul>
      </li>
    );
  }

  renderBrowse(type, linkColor) {
    if (!this.state.browseLinks.length) {
      return null;
    }

    const links = this.state.browseLinks.map(item => {
      return type === 'mobile' ? (
        <Link
          key={item.label}
          className="menu-item"
          to={item.link || ''}
          state={{ routeName: item.label }}
        >
          {item.label}
        </Link>
      ) : (
        <li key={item.label}>
          <Link to={item.link || ''} state={{ routeName: item.label }}>
            {item.label}
          </Link>
        </li>
      );
    });

    const container =
      type === 'mobile' ? (
        links
      ) : (
        <li className="dropdown">
          <Link
            to="#"
            style={{ color: linkColor }}
            className="dropdown-toggle"
            data-toggle="dropdown"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Browse <span className="caret" />
          </Link>
          <ul className="dropdown-menu">{links}</ul>
        </li>
      );

    return container;
  }

  renderNavbarItemsDesktop(isHome) {
    const linkColor = isHome ? '#fff' : '#777';

    return (
      <div className="desktop-nav">
        {/* LEFT */}
        <ul className="nav navbar-nav pull-left main-nav">
          {this.renderBrowse('desktop', linkColor)}
          {this.renderMyActivityLinksDesktop(linkColor)}
          {this.renderSearchLinkDesktop(linkColor)}
          {this.renderRunlists(linkColor)}
          {this.renderLiveSimulcastLink(linkColor)}
        </ul>

        {/* RIGHT */}
        <div className="pull-right" style={{ alignItems: 'center' }}>
          <ul className="nav navbar-nav">
            {this.renderConnectionIndicator(linkColor, isHome)}
          </ul>
          <NotificationsDropdown baseClass="pull-left" />
          <ul className="nav navbar-nav pull-left user-nav">
            {this.renderHelpLinks(linkColor)}
            {this.renderProfileLinks(linkColor)}
          </ul>
        </div>
      </div>
    );
  }

  handleMenu = state => {
    this.setState({ menuOpen: state.isOpen });
  };

  renderNavbarItemsMobile(isHome) {
    const { customerTermsLink } = this.props;

    return (
      <div className="mobile-nav">
        <Menu
          right
          styles={styles}
          isOpen={this.state.menuOpen}
          onStateChange={this.handleMenu}
        >
          <Link className="menu-item" to="/" state={{ routerName: 'Home' }}>
            Home
          </Link>
          {this.renderMenuHR()}
          {this.renderBrowse('mobile')}
          {this.renderMenuHR()}
          <Link
            className="menu-item"
            to="/myactivity"
            state={{ routeName: 'My Activity' }}
          >
            Activity Timeline
          </Link>
          {!getConfig('disableBuyerActivity') && (
            <Link
              className="menu-item"
              to="/status"
              state={{ routeName: 'Buyer Activity' }}
            >
              Buyer Activity
            </Link>
          )}
          {!getConfig('disableSellerActivity') && (
            <Link
              className="menu-item"
              to="/inventory"
              state={{ routeName: 'Seller Activity' }}
            >
              Seller Activity
            </Link>
          )}
          {this.renderMenuHR()}
          <Link
            className="menu-item"
            to="/search"
            state={{ routeName: 'Search' }}
          >
            Search
          </Link>
          {this.renderMenuHR()}
          <Link
            className="menu-item"
            to="/notifications"
            state={{ routeName: 'Notifications' }}
          >
            Notifications
          </Link>
          {this.renderMenuHR()}
          {getConfig('supportLink') !== null && (
            <a
              className="menu-item"
              href={getConfig('supportLink') || '/info/contact-us'}
              target="_blank"
              rel="noreferrer"
            >
              Contact Us
            </a>
          )}
          {getConfig('faqLink') !== null && (
            <a
              className="menu-item"
              href={getConfig('faqLink') || '/info/faq'}
              target="_blank"
              rel="noreferrer"
            >
              FAQ
            </a>
          )}
          {customerTermsLink && (
            <a
              className="menu-item"
              href={customerTermsLink}
              target="_blank"
              rel="noreferrer"
            >
              {`Terms & Conditions`}
            </a>
          )}
          {this.renderMenuHR()}
          <Link
            className="menu-item"
            to="/profile"
            state={{ routeName: 'Profile' }}
          >
            Profile
          </Link>
          <Link
            className="menu-item"
            to="/logout"
            state={{ routeName: 'Logout' }}
            onClick={this.handleSignout}
          >
            Sign Out
          </Link>
        </Menu>
      </div>
    );
  }

  renderBreadcrumbs(breadcrumbs) {
    const breadcrumbItems = [
      { text: 'Home', link: '/' },
      ...breadcrumbs.crumbs,
    ].map((crumb, index, arr) => {
      const isLastItem = index === arr.length - 1;

      return (
        <div key={index} style={styles.breadcrumbItem}>
          <Link
            to={crumb.link || '#'}
            state={{ routeName: crumb.text }}
            style={{
              color: getTemplate(
                this.props.template,
                'homeSectionViewAll.color'
              ),
              pointerEvents: isLastItem ? 'none' : 'auto',
            }}
          >
            {crumb.text}
          </Link>
          {!isLastItem && (
            <div
              className="fa fa-chevron-right"
              style={{
                fontSize: 10,
                padding: '6px 10px 0 10px',
                color: getTemplate(
                  this.props.template,
                  'homeSectionViewAll.color'
                ),
              }}
            />
          )}
        </div>
      );
    });

    return (
      <div ref={el => (this.refBreadcrumbs = el)}>
        <div style={{ display: 'flex' }}>{breadcrumbItems}</div>
      </div>
    );
  }

  renderItemsNav(breadcrumbs, navItems) {
    const { location } = this.props;
    const isVDP = location.pathname.includes('/item/');
    const navItemsCount = navItems.length;
    const itemId = location.pathname.replace(/\D/g, '');
    const itemIndex = navItems.indexOf(itemId);
    // Reload document so Darwin Bookout ("Vehicle Values") will pick up item changes
    //  NOTE: 'reloadDocument' prop defaults to 'false'
    const shouldReload = isVDP && !!getConfig('stratosConfig');

    if (!isVDP || !navItemsCount || navItemsCount < 2 || itemIndex === -1) {
      return null;
    }

    const prevIndex = itemIndex > 0 ? itemIndex - 1 : -1;
    const nextIndex = itemIndex < navItemsCount - 1 ? itemIndex + 1 : -1;
    const prevCrumb =
      breadcrumbs.crumbs && breadcrumbs.crumbs[0]
        ? breadcrumbs.crumbs[0]
        : null;
    // NOTE: - Links must have a to value. Use # if you want it to be a dead link
    const backLink =
      prevCrumb && !prevCrumb.link.includes('/item/') ? prevCrumb.link : '/';
    const firstLink = `/item/${navItems[0]}`;
    const prevLink = prevIndex === -1 ? null : `/item/${navItems[prevIndex]}`;
    const counterText =
      itemIndex === -1 ? '' : `${itemIndex + 1} of ${navItemsCount}`;
    const nextLink = nextIndex === -1 ? null : `/item/${navItems[nextIndex]}`;
    const lastLink = `/item/${navItems[navItemsCount - 1]}`;
    const linkStyle = { color: '#777' };

    return (
      <div ref={el => (this.refItemsNav = el)}>
        <div className="btn-group">
          <Link
            to={backLink || '#'}
            className="btn btn-default"
            style={{ ...linkStyle, pointerEvents: backLink ? 'auto' : 'none' }}
            reloadDocument={shouldReload}
          >
            <span
              className="fa fa-chevron-circle-left"
              style={{ color: backLink ? 'inherit' : '#D0D0D0' }}
            />
            <span
              style={{ marginLeft: 5, color: backLink ? 'inherit' : '#D0D0D0' }}
            >
              Back
            </span>
          </Link>
          <Link
            title="first"
            to={firstLink}
            className="btn btn-default"
            reloadDocument={shouldReload}
            style={{
              ...linkStyle,
              pointerEvents: firstLink === location.pathname ? 'none' : 'auto',
            }}
          >
            <span
              className="fa fa-angle-double-left"
              style={{
                color: firstLink === location.pathname ? '#D0D0D0' : 'inherit',
              }}
            />
          </Link>
          <Link
            title="prev"
            to={prevLink || '#'}
            className="btn btn-default"
            reloadDocument={shouldReload}
            style={{ ...linkStyle, pointerEvents: prevLink ? 'auto' : 'none' }}
          >
            <span
              className="fa fa-chevron-left"
              style={{ color: prevLink ? 'inherit' : '#D0D0D0' }}
            />
          </Link>
          <Link
            className="btn btn-default"
            to="#"
            style={{ color: '#777', pointerEvents: 'none' }}
          >
            {counterText || ' '}
          </Link>
          <Link
            title="next"
            to={nextLink || '#'}
            className="btn btn-default"
            reloadDocument={shouldReload}
            style={{ ...linkStyle, pointerEvents: nextLink ? 'auto' : 'none' }}
          >
            <span
              className="fa fa-chevron-right"
              style={{ color: nextLink ? 'inherit' : '#D0D0D0' }}
            />
          </Link>
          <Link
            title="last"
            to={lastLink || '#'}
            className="btn btn-default"
            reloadDocument={shouldReload}
            style={{
              ...linkStyle,
              pointerEvents: lastLink === location.pathname ? 'none' : 'auto',
            }}
          >
            <span
              className="fa fa-angle-double-right"
              style={{
                color: lastLink === location.pathname ? '#D0D0D0' : 'inherit',
              }}
            />
          </Link>
        </div>
      </div>
    );
  }

  renderSubNav() {
    const { retailView, location } = this.props;
    const hideOnPage = ['/', '/condition'].includes(location.pathname);
    const bcCrumbs = sessionStorage.getItem('bcCrumbs');
    const disableBreadcrumbs = getConfig('disableBreadcrumbs');

    if (hideOnPage || retailView || !bcCrumbs) {
      return null;
    }

    const breadcrumbs = JSON.parse(bcCrumbs);
    const navItemsStr = sessionStorage.getItem('navItems');
    const navItems = (navItemsStr || '').split(',');

    return (
      <div
        className='topnav-subnav__wrapper'
        style={{
          // ...styles.subNav,
          justifyContent: disableBreadcrumbs ? 'flex-end' : 'space-between',
        }}
      >
        {!disableBreadcrumbs && this.renderBreadcrumbs(breadcrumbs)}
        {this.renderItemsNav(breadcrumbs, navItems)}
      </div>
    );
  }

  render() {
    const { pathname } = this.props.location;
    const isCondition = pathname === '/condition';
    const isHome = pathname === '/' || pathname === '/home';

    if (isCondition) return null;

    return (
      <div
        ref={el => (this.navRef = el)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          pointerEvents: this.state.isTermsModalOpen ? 'none' : 'auto',
        }}
      >
        <IASConnectModal
          isOpen={this.state.iasConnectModalOpen}
          onClose={() => this.setState({ iasConnectModalOpen: false })}
          url={this.state.iasConnectUrl}
        />

        <TermsAndConditionsModal
          isOpen={this.state.isTermsModalOpen}
          onClose={() => this.setState({ isTermsModalOpen: false })}
          handleTermsClick={this.handleTermsClick}
        />

        <div
          className="top-nav"
          style={{ position: isHome ? 'fixed' : 'sticky' }}
        >
          <div
            className="container-fluid"
            style={{
              ...styles.fluidContainer,
              color: isHome ? '#fff' : 'inherit',
              backgroundColor: isHome ? 'rgba(0,0,0,.75)' : '#ffff',
              borderBottom: isHome ? 'none' : `1px solid #D0D0D0`,
            }}
          >
            {this.renderNavHeader(isHome)}
            {this.renderNavbarItemsDesktop(isHome)}
            {this.renderNavbarItemsMobile(isHome)}
          </div>
        </div>

        {this.renderSubNav()}
      </div>
    );
  }
}

TopNav.propTypes = {
  allRunlists: PropTypes.object,
  customerTermsLink: PropTypes.string,
  loadMarketplaces: PropTypes.func.isRequired,
  loadSimulcastStatus: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  marketplaces: PropTypes.object.isRequired,
  retailView: PropTypes.bool.isRequired,
  simulcastStatus: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  const {
    ariTermsAndConditions,
    customerPlatformLink,
    customerTermsLink,
    events,
    onlineConnected,
    marketplaceFeatures,
    marketplaces,
    realTimeConnected,
    retailView,
    simulcastStatus,
    template,
    unauthorizedEventIds,
    userProfile,
    userSelectedLocation,
  } = state.entities;
  return {
    ariTermsAndConditions,
    customerPlatformLink,
    customerTermsLink,
    events,
    onlineConnected,
    marketplaceFeatures,
    marketplaces,
    realTimeConnected,
    retailView,
    simulcastStatus,
    template,
    unauthorizedEventIds,
    userProfile,
    userSelectedLocation,
  };
};

export default connect(mapStateToProps, {
  getCoreMetaDetails,
  loadARITerms,
  loadEvents,
  loadGeocode,
  loadMarketplaceFeatures,
  loadMarketplaces,
  loadSimulcastStatus,
  loadSpinCars,
  loadUserProfile,
  loadUserSelectedLocation,
  loadWatchlistObject,
  loadWishlistItems,
  resetBidMessageHistoryRealTime,
  resetBidMessagesRealTime,
  setCustomerPlatformLink,
  setCustomerTermsLink,
  setListingStatusIds,
  setOnlineConnected,
  setRealTimeConnected,
  setTemplate,
  updateBidMessageHistoryRealTime,
  updateBidMessagesRealTime,
})(TopNav);
