import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withRouter from '../../hocs/withRouter';
import { findIndex, get, has, isObject, isString } from 'lodash';
import QRCode from 'qrcode.react';
import { browserName } from 'react-device-detect';
import { Link } from 'react-router-dom';
import TableRowCheckbox from './TableRowCheckbox';
import TableRowImage from './TableRowImage';
import TableTextFilter from './TableTextFilter';
import {
  getConfig,
  compose,
  commafy,
  commafyCurrency,
  makeTitle,
  makeReadableDate,
  filterLogicIncludes,
  sortDate,
  makeAssetLink,
  filterLogicStartsWith,
} from '../../utils/helpers';
import { dataPlaceholder } from '../../utils/constants';
import styles from './styles';
/* TODO: Refactor
  1. Utilze Web API + css to show/hide/adjust calls+columns when printing
     -> see:  https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_media_queries/Printing
  2. Decouple static columns with action column to make them conditional/configurable
  3. ReactTable has a bunch of capabilities not used here, like adjustable column widths
*/
const withTable = ComposedComponent => {
  return class WrappedComponent extends Component {
    state = {
      allSelected: false,
      selectedData: [],
      currentData: [],
      screenWidth: undefined,
      printableWidth: 1056,
    };

    // context provider
    static childContextTypes = {
      allSelected: PropTypes.bool,
      selectedData: PropTypes.array,
      currentData: PropTypes.array,
      updateCurrentData: PropTypes.func,
    };

    // setting contexts
    getChildContext = () => {
      return {
        allSelected: this.state.allSelected,
        selectedData: this.state.selectedData,
        currentData: this.state.currentData,
        updateCurrentData: this.updateCurrentData,
      };
    };

    componentDidMount() {
      window.addEventListener('resize', this.handleResize);
    }

    componentDidUpdate(prevProps, prevState) {
      // this should be the init
      if (this.props.tableRef !== prevProps.tableRef) {
        this.updateCurrentData();
      }
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
      this.setState({ screenWidth: window.innerWidth });
    };

    updateCurrentData = () => {
      // this gets called whenever a filter runs (text or dropdown) or a sort runs so the CSV and PRINT
      // buttons are updated with the filtered and sorted data.
      // we get the tableRef from DataTable.js via the store
      if (this.props.tableRef) {
        this.setState(prevState => {
          const currentData = this.props.tableRef
            .getResolvedState()
            .sortedData.map(item => item._original);
          const currentDataIds = currentData.map(item => item.itemId);
          const selectedData = prevState.selectedData.filter(item =>
            currentDataIds.includes(item.itemId)
          );
          const allSelected = !!(
            selectedData.length && selectedData.length === currentData.length
          );
          return {
            currentData,
            selectedData,
            allSelected,
          };
        });
      }
    };

    /**
     * Update (2025.01) - Utilize immutable `row.index` as unique row identifier 
     * - `row.index` _does not mutate regardless of filtering, sort, etc._
     */ 
    findRowIndex(row) {
      const { selectedData } = this.state;
      
      if (has(row, 'index')) {
        return findIndex(selectedData, {
          index: row.index
        })
      }

      return -1;
    }

    handleSelectRow = (e, row) => {
      this.setState(prevState => {
        const selectedData = [];
        let allSelected = false;
        const selectedDataIndex = this.findRowIndex(row);

        // Row not selected --> add to 'selectedData' & check if all rows selected
        if (selectedDataIndex === -1) {
          row.original.index = row.index;
          selectedData.push(...prevState.selectedData, row.original);
          allSelected = selectedData.length === prevState.currentData.length;
        } 
        
        // Row already selected --> remove from 'selectedData'
        else {
          selectedData.push(
            ...prevState.selectedData.slice(0, selectedDataIndex),
            ...prevState.selectedData.slice(selectedDataIndex + 1),
          );
        }

        return {
          selectedData,
          allSelected,
        };
      });
    };

    handleSelectAll = ({ data }) => {
      const allSelected = !!(
        !this.state.selectedData.length ||
        this.state.selectedData.length !== data.length
      );

      const selectedData = allSelected 
        ? data.map(item => {
          if (!item._original.index) item._original.index = item._index;
          return item._original;
        }) 
        : [];

      this.setState({
        allSelected,
        selectedData,
      });
    };

    // this is used to dynamically calculate the column widths for both the main component AND the print component
    findColumnWidths = (list = [], printable = false) => {
      // NOTE: - above 14 columns and you're pushing it...
      // The idea is to get the total container width, subtract the widths of
      // certain fixed column widths the spread the remaining width
      // accross the remaining columns

      const totalColumnCount = list.length;

      const containerWidth = printable
        ? this.state.printableWidth // fixed to 1056
        : this.state.screenWidth;

      const extraSpace =
        printable && browserName === 'Safari' ? 150 : printable ? 50 : 50; // safari adds print margins ~10cm
      const minColumnWidth = printable ? 20 : 75;
      const maxColumnWidth = 150;

      const defaultFixedWidths = {
        arbitrationStatus: printable ? 50 : 100,
        checkbox: printable ? 0 : 22,
        image: printable ? 120 : 120,
        vin: printable ? 70 : 168,
        lotNumber: printable ? 70 : 70,
        stockNumber: printable ? 70 : 90,
        mileage: printable ? 70 : 100,
        qrCode: printable ? 70 : 80,
        details: printable ? 0 : 120,
        buttons: printable ? 0 : 120,
        year: printable ? 50 : 60,
        retailOrWholesale: printable ? 90 : 100,
        newOrUsed: printable ? 90 : 100,
      };

      const fixedWidthColumns = {};
      const nonFixedWidthColumns = {};

      // puts each column in either fixedWidthColumns or nonFixedWidthColumns
      // if in fixedWidthColumns gives it the fixed width
      // if in nonFixedWidthColumns we have to calculate
      list.forEach(item => {
        if (isObject(item)) {
          if (item.width || item.width === 0) {
            fixedWidthColumns[item.id] = item.width;
          } else if (
            defaultFixedWidths[item.id] ||
            defaultFixedWidths[item.id] === 0
          ) {
            fixedWidthColumns[item.id] = defaultFixedWidths[item.id];
          } else {
            nonFixedWidthColumns[item.id] = undefined;
          }
        } else if (isString(item)) {
          if (defaultFixedWidths[item] || defaultFixedWidths[item] === 0) {
            fixedWidthColumns[item] = defaultFixedWidths[item];
          } else {
            nonFixedWidthColumns[item] = undefined;
          }
        }
      });

      const fixedColumnCount = Object.values(fixedWidthColumns).length;
      const nonFixedColumnCount = totalColumnCount - fixedColumnCount;

      const sumOfFixedColumnWidths = Object.values(fixedWidthColumns).reduce(
        (a, b) => a + b,
        0
      );

      let remainingWidth = containerWidth - sumOfFixedColumnWidths - extraSpace;
      if (remainingWidth < 0) remainingWidth = 0;

      let avgColumnWidth = Math.floor(remainingWidth / nonFixedColumnCount);
      if (avgColumnWidth < minColumnWidth) avgColumnWidth = minColumnWidth;
      else if (avgColumnWidth > maxColumnWidth) avgColumnWidth = maxColumnWidth;

      // make all the non-fixed column widths the avg remaining
      for (let key in nonFixedWidthColumns) {
        if (nonFixedWidthColumns.hasOwnProperty(key)) {
          nonFixedWidthColumns[key] = avgColumnWidth;
        }
      }

      const sumOfNonFixedColumnWidths = Object.values(
        nonFixedWidthColumns
      ).reduce((a, b) => a + b, 0);

      const columnWidths = {
        ...fixedWidthColumns,
        ...nonFixedWidthColumns,

        meta: {
          avg: avgColumnWidth, //fallback
          containerWidth,
          sumOfFixedColumnWidths,
          sumOfNonFixedColumnWidths,
          remainingWidth,
          extraSpace,
          minColumnWidth,
          maxColumnWidth,
          fixedColumnCount,
          nonFixedColumnCount,
          totalColumnCount,
        },
      };

      return columnWidths;
    };

    makeColumns = list => {
      // you can make your own column or type the string name of a commonly used one below
      // set up for awg hits not es hits, althgough we could adjust to handle both

      const columnWidths = this.findColumnWidths(list, false);
      const columnPrintWidths = this.findColumnWidths(list, true);
      const columns = list
        .map((item, index) => {
          let column = undefined;

          // if item is a simple object assume it is an explicit override
          if (isObject(item)) {
            if (item.id === 'buttons') {
              item.headerClassName = 'datatable-column__actions';
              item.className = 'datatable-column__actions'
            }
            column = item;
          }
          
          // if item is string use these
          else if (isString(item)) {
            switch (item) {
              // special
              case 'checkbox': {
                column = {
                  className: 'datatable-column__checkbox',
                  headerClassName: 'datatable-column__checkbox',
                  Header: props => (
                    <TableRowCheckbox
                      selectedData={this.state.selectedData}
                      allSelected={this.state.allSelected}
                      onClick={() => this.handleSelectAll(props)}
                    />
                  ),
                  accessor: row => row.itemId || row.id,
                  id: 'checkbox',
                  headerStyle: {
                    background: 'none',
                    borderRight: 'none',
                  },
                  sortable: false,
                  filterable: false,
                  hideCSV: true,
                  hidePrint: true,
                  Cell: row => (
                    <TableRowCheckbox
                      id={row.index}
                      row={row}
                      selectedData={this.state.selectedData}
                      allSelected={this.state.allSelected}
                      onClick={e => this.handleSelectRow(e, row)}
                    />
                  ),
                };
                break;
              }

              case 'image': {
                column = {
                  className: 'datatable-column__image',
                  headerClassName: 'datatable-column__image',
                  Header: 'Image',
                  accessor: row =>
                    row.mainImage ||
                    get(row, 'smallImageUrls[0]') ||
                    makeAssetLink('placeholder.png'),
                  id: 'image',
                  headerStyle: { background: 'none' },
                  sortable: false,
                  filterable: false,
                  hideCSV: true,
                  hidePrint: true,
                  Cell: row => (
                    <TableRowImage
                      src={row.value || row.row.image || row.original.image}
                    />
                  ),
                };
                break;
              }

              case 'qrCode': {
                column = {
                  className: 'datatable-column__qrcode',
                  headerClassName: 'datatable-column__qrcode',
                  Header: 'Scan Vin',
                  accessor: row => row.vin || row.vIN || row.VIN,
                  id: 'qrCode',
                  Cell: row => (
                    <QRCode value={row.value || ''} renderAs="svg" size={60} />
                  ),
                  sortable: false,
                  filterable: false,
                  hideCSV: true,
                  hidePrint: true,
                };
                break;
              }

              // text filters
              // inventoryType - 'New' | 'Used'
              case 'newOrUsed': {
                column = {
                  className: 'datatable-column__neworused',
                  headerClassName: 'datatable-column__neworused',
                  Header: 'New / Used',
                  accessor: row =>
                    row.inventoryType ? row.inventoryType.substring(0,1) : dataPlaceholder,
                  id: 'newOrUsed',
                  sortable: true,
                };
                break;
              }
              
              /* saleType - 'Wholesale' | 'Retail' */
              case 'retailOrWholesale': {
                column = {
                  className: 'datatable-column__retailorwholesale',
                  headerClassName: 'datatable-column__retailorwholesale',
                  Header: 'Retail / Wholesale',
                  accessor: row =>
                    row.saleType ? row.saleType.substring(0,1) : dataPlaceholder,
                  id: 'retailOrWholesale',
                  sortable: true,
                };
                break;
              }

              case 'vin': {
                column = {
                  className: 'datatable-column__vin',
                  headerClassName: 'datatable-column__vin',
                  Header: getConfig('localization') === 'en-uk' ? 'REG' : 'VIN',
                  accessor: row =>
                    row.vin || row.vIN || row.VIN || dataPlaceholder,
                  id: 'vin',
                  Filter: props => (
                    <TableTextFilter
                      placeholder={'enter vin'}
                      filter={props.filter}
                      onChange={props.onChange}
                      updateCurrentData={this.updateCurrentData}
                    />
                  ),
                  filterMethod: filterLogicIncludes,
                };
                break;
              }

              case 'stockNumber': {
                column = {
                  className: 'datatable-column__stocknumber',
                  headerClassName: 'datatable-column__stocknumber',
                  Header: 'Stock Number',
                  accessor: row => row.stockNumber || dataPlaceholder,
                  id: item,
                  Filter: props => (
                    <TableTextFilter
                      placeholder={'enter stock'}
                      filter={props.filter}
                      onChange={props.onChange}
                      updateCurrentData={this.updateCurrentData}
                    />
                  ),
                  filterMethod: filterLogicIncludes,
                };
                break;
              }

              // simple
              case 'lotNumber': {
                column = {
                  className: 'datatable-column__lotnumber',
                  headerClassName: 'datatable-column__lotnumber',
                  Header: 'Lot Number',
                  accessor: row =>
                    row.lotNumber || row.lotNo || dataPlaceholder,
                  id: item,
                  filterable: false,
                };
                break;
              }

              case 'year': {
                column = {
                  className: 'datatable-column__year',
                  headerClassName: 'datatable-column__year',
                  Header: 'Year',
                  accessor: row =>
                    row.year || row.vehicleYear || row.yr || dataPlaceholder,
                  id: 'year',
                  filterable: false,
                };
                break;
              }

              case 'make': {
                column = {
                  className: 'datatable-column__make',
                  headerClassName: 'datatable-column__make',
                  Header: 'Make',
                  accessor: row => row.make || dataPlaceholder,
                  id: 'make',
                };
                break;
              }

              case 'model': {
                column = {
                  className: 'datatable-column__model',
                  headerClassName: 'datatable-column__model',
                  Header: 'Model',
                  accessor: row => row.model || dataPlaceholder,
                  id: 'model',
                };
                break;
              }

              case 'mileage': {
                column = {
                  className: 'datatable-column__mileage',
                  headerClassName: 'datatable-column__mileage',
                  Header: `Odometer`,
                  accessor: row =>
                    commafy(row.mileage) ||
                    commafy(row.odometer) ||
                    dataPlaceholder,
                  id: 'mileage',
                  filterable: false,
                };
                break;
              }

              case 'grade': {
                column = {
                  className: 'datatable-column__crgrade',
                  headerClassName: 'datatable-column__crgrade',
                  Header: 'Grade',
                  accessor: row => row.crGrade > 0 || dataPlaceholder,
                  id: 'crGrade',
                  filterable: false,
                };
                break;
              }

              case 'engine': {
                column = {
                  className: 'datatable-column__vehicledata',
                  headerClassName: 'datatable-column__vehicledata',
                  Header: 'Engine',
                  accessor: row => row.engine || dataPlaceholder,
                  id: 'engine',
                };
                break;
              }

              case 'transmission': {
                column = {
                  className: 'datatable-column__vehicledata',
                  headerClassName: 'datatable-column__vehicledata',
                  Header: 'Trans',
                  accessor: row => row.transmission || dataPlaceholder,
                  id: 'transmission',
                };
                break;
              }

              case 'extColor': {
                column = {
                  className: 'datatable-column__vehicledata',
                  headerClassName: 'datatable-column__vehicledata',
                  Header: 'Color',
                  accessor: row =>
                    row.extColor || row.exteriorColor || dataPlaceholder,
                  id: item,
                  Filter: props => (
                    <TableTextFilter
                      placeholder={'enter color'}
                      filter={props.filter}
                      onChange={props.onChange}
                      updateCurrentData={this.updateCurrentData}
                    />
                  ),
                  filterMethod: filterLogicStartsWith,
                };
                break;
              }

              case 'seller': {
                column = {
                  className: 'datatable-column__seller',
                  headerClassName: 'datatable-column__seller',
                  Header: 'Seller',
                  accessor: row =>
                    row.sellerName || row.sName || dataPlaceholder,
                  id: 'seller',
                };
                break;
              }

              case 'buyer': {
                column = {
                  className: 'datatable-column__buyer',
                  headerClassName: 'datatable-column__buyer',
                  Header: 'Buyer',
                  accessor: row =>
                    row.bName || row.buyerName || dataPlaceholder,
                  id: 'buyerName',
                };
                break;
              }

              case 'bidder': {
                column = {
                  className: 'datatable-column__buyer',
                  headerClassName: 'datatable-column__buyer',
                  Header: 'Buyer',
                  accessor: row => row.bidderName || dataPlaceholder,
                  id: 'bidder',
                };
                break;
              }

              case 'highBidder': {
                column = {
                  className: 'datatable-column__highbidder',
                  headerClassName: 'datatable-column__highbidder',
                  Header: 'High Bidder',
                  accessor: row => row.highBidderName || dataPlaceholder,
                  id: 'highBidderName',
                };
                break;
              }

              case 'timesToRerun': {
                column = {
                  className: 'datatable-column__rerun',
                  headerClassName: 'datatable-column__rerun',
                  Header: 'Times to Rerun',
                  accessor: row => commafy(row.rerunRequest),
                  id: 'rerunRequest',
                  filterable: false,
                };
                break;
              }

              case 'event': {
                column = {
                  className: 'datatable-column__event',
                  headerClassName: 'datatable-column__event',
                  Header: 'Event',
                  accessor: row => row.eventName,
                  id: 'eventName',
                };
                break;
              }

              case 'type': {
                column = {
                  className: 'datatable-column__type',
                  headerClassName: 'datatable-column__type',
                  Header: 'Type',
                  accessor: row => row.type || dataPlaceholder,
                  id: 'type',
                };
                break;
              }

              case 'title': {
                column = {
                  className: 'datatable-column__title',
                  headerClassName: 'datatable-column__title',
                  Header: 'Title',
                  accessor: row => row.type || dataPlaceholder,
                  id: 'title',
                };
                break;
              }

              case 'status': {
                column = {
                  className: 'datatable-column__status',
                  headerClassName: 'datatable-column__status',
                  Header: 'Status',
                  accessor: row => {
                    const idx = row.status.indexOf('<br/>');
                    row.status =
                      idx === -1 ? row.status : row.status.slice(0, idx);
                    return row.status || dataPlaceholder;
                  },
                  id: 'status',
                };
                break;
              }

              case 'listingStatus': {
                column = {
                  className: 'datatable-column__status',
                  headerClassName: 'datatable-column__status',
                  Header: 'Status',
                  accessor: row => row.listingStatus || dataPlaceholder,
                  id: 'listingStatus',
                };
                break;
              }

              // dates
              case 'dateAdded': {
                column = {
                  className: 'datatable-column__dates',
                  headerClassName: 'datatable-column__dates',
                  Header: 'Date Added',
                  accessor: row => makeReadableDate(row.dateAdded),
                  id: 'dateAdded',
                  filterable: false,
                  sortMethod: sortDate,
                };
                break;
              }

              case 'saleDate': {
                column = {
                  className: 'datatable-column__dates',
                  headerClassName: 'datatable-column__dates',
                  Header: 'Date',
                  accessor: row =>
                    makeReadableDate(row.saleDate) || dataPlaceholder,
                  id: 'saleDate',
                  filterable: false,
                  sortMethod: sortDate,
                };
                break;
              }

              case 'soldDate': {
                column = {
                  className: 'datatable-column__dates',
                  headerClassName: 'datatable-column__dates',
                  Header: 'Sold Date',
                  accessor: row => makeReadableDate(row.soldDate),
                  id: 'soldDate',
                  filterable: false,
                  sortMethod: sortDate,
                };
                break;
              }

              case 'purchaseDate': {
                column = {
                  className: 'datatable-column__dates',
                  headerClassName: 'datatable-column__dates',
                  Header: 'Purchase Date',
                  accessor: row => makeReadableDate(row.dateCreated),
                  id: 'purchaseDate',
                  filterable: false,
                  sortMethod: sortDate,
                };
                break;
              }

              case 'offerDate': {
                column = {
                  className: 'datatable-column__dates',
                  headerClassName: 'datatable-column__dates',
                  Header: 'Offer Date',
                  accessor: row => makeReadableDate(row.dateCreated),
                  id: 'offerDate',
                  filterable: false,
                  sortMethod: sortDate,
                };
                break;
              }

              case 'bidDate': {
                column = {
                  className: 'datatable-column__dates',
                  headerClassName: 'datatable-column__dates',
                  Header: 'Bid Date',
                  accessor: row => makeReadableDate(row.dateCreated),
                  id: 'dateCreated',
                  filterable: false,
                  sortMethod: sortDate,
                };
                break;
              }

              case 'sDate': {
                column = {
                  className: 'datatable-column__dates',
                  headerClassName: 'datatable-column__dates',
                  Header: 'Date',
                  accessor: row =>
                    makeReadableDate(row.sDate) || dataPlaceholder,
                  id: 'sDate',
                  filterable: false,
                  sortMethod: sortDate,
                };
                break;
              }

              case 'daysInMarketplace': {
                column = {
                  className: 'datatable-column__daysinmarketplace',
                  headerClassName: 'datatable-column__daysinmarketplace',
                  Header: 'Days In Marketplace',
                  accessor: row => {
                    const daysInMarketplace = Math.floor(
                      (new Date().getTime() -
                        new Date(
                          row.dateAdded || row.dateAddedToInv || row.dateCreated
                        ).getTime()) /
                        (1000 * 60 * 60 * 24)
                    );
                    return daysInMarketplace;
                  },
                  id: 'daysInMarketplace',
                  filterable: false,
                };
                break;
              }

              // amounts
              case 'amount': {
                column = {
                  className: 'datatable-column__prices',
                  headerClassName: 'datatable-column__prices',
                  Header: 'Price',
                  accessor: row => commafyCurrency(row.amount || row.gross),
                  id: 'amount',
                  filterable: false,
                };
                break;
              }

              case 'reservePrice': {
                column = {
                  className: 'datatable-column__prices',
                  headerClassName: 'datatable-column__prices',
                  Header: 'Reserve',
                  accessor: row =>
                    commafyCurrency(row.reservePrice) || dataPlaceholder,
                  id: 'reservePrice',
                  filterable: false,
                };
                break;
              }

              case 'buyerOffer': {
                column = {
                  className: 'datatable-column__prices',
                  headerClassName: 'datatable-column__prices',
                  Header: 'Buyer Offer',
                  accessor: row => commafyCurrency(row.amount),
                  id: 'buyerOffer',
                  filterable: false,
                };
                break;
              }

              case 'buyNow': {
                column = {
                  className: 'datatable-column__prices',
                  headerClassName: 'datatable-column__prices',
                  Header: 'Buy Now',
                  accessor: row =>
                    commafyCurrency(row.outrightPrice) || dataPlaceholder,
                  id: 'buyNow',
                  filterable: false,
                };
                break;
              }

              case 'currentHighBid': {
                column = {
                  className: 'datatable-column__prices',
                  headerClassName: 'datatable-column__prices',
                  Header: 'High Bid',
                  accessor: row => commafyCurrency(row.currentHighBid),
                  id: 'currentHighBid',
                  filterable: false,
                };
                break;
              }

              case 'myHighBid': {
                column = {
                  className: 'datatable-column__prices',
                  headerClassName: 'datatable-column__prices',
                  Header: 'My Max Bid',
                  accessor: row =>
                    commafyCurrency(row.myHighBid) || dataPlaceholder,
                  id: 'myHighBid',
                  filterable: false,
                };
                break;
              }

              case 'sellerWants': {
                column = {
                  className: 'datatable-column__prices',
                  headerClassName: 'datatable-column__prices',
                  Header: 'Seller Wants',
                  accessor: row => {
                    if (
                      row.status === 'Buyer Cancelled' ||
                      row.status === 'Seller Rejected'
                    ) {
                      return dataPlaceholder;
                    }

                    if (row.requiredAmount) {
                      return commafyCurrency(row.requiredAmount);
                    }

                    if (!row.requiredAmount && row.status === 'Active') {
                      return 'Awaiting Response';
                    }

                    return dataPlaceholder;
                  },
                  id: 'sellerWants',
                  filterable: false,
                };
                break;
              }

              case 'sellFee': {
                column = {
                  className: 'datatable-column__fees',
                  headerClassName: 'datatable-column__fees',
                  Header: 'Sell Fee',
                  accessor: row => commafyCurrency(row.sellFee),
                  id: 'sellFee',
                  filterable: false,
                };
                break;
              }

              case 'buyFee': {
                column = {
                  className: 'datatable-column__fees',
                  headerClassName: 'datatable-column__fees',
                  Header: 'Buy Fee',
                  accessor: row => commafyCurrency(row.buyFee),
                  id: 'buyFee',
                  filterable: false,
                };
                break;
              }

              case 'shippingStatusText': {
                column = {
                  className: 'datatable-column__misc',
                  headerClassName: 'datatable-column__misc',
                  Header: 'Shipping Status',
                  accessor: row => row.shippingStatusText || dataPlaceholder,
                  id: 'shippingStatusText',
                  filterable: false,
                };
                break;
              }

              // details button
              case 'details': {
                column = {
                  className: 'datatable-column__actions',
                  headerClassName: 'datatable-column__actions',
                  Header: 'Actions',
                  headerStyle: { background: 'none' },
                  id: 'buttons',
                  width: columnWidths.buttons,
                  sortable: false,
                  filterable: false,
                  hideCSV: true,
                  hidePrint: true,
                  Cell: row => (
                    <div style={styles.buttonGroup}>
                      <Link
                        to={`/item/${row.original.itemId}`}
                        style={styles.button}
                      >
                        View Details
                      </Link>
                    </div>
                  ),
                };
                break;
              }

              // watchlist button
              case 'watchlist': {
                column = {
                  className: 'datatable-column__actions',
                  headerClassName: 'datatable-column__actions',
                  Header: 'Actions',
                  headerStyle: { background: 'none' },
                  id: 'buttons',
                  width: columnWidths.buttons,
                  sortable: false,
                  filterable: false,
                  Cell: row => (
                    <div style={styles.buttonGroup}>
                      <Link
                        to={`/item/${row.original.itemId}`}
                        style={styles.button}
                      >
                        Watchlist
                      </Link>
                    </div>
                  ),
                };
                break;
              }

              case 'locked': {
                column = {
                  className: 'datatable-column__locked',
                  headerClassName: 'datatable-column__locked',
                  header: 'lock',
                  Header: props => <span className={`fa fa-lock`} />,
                  accessor: row => row.locked,
                  id: 'locked',
                  Cell: row => {
                    return row.original.locked ? (
                      <span className={`fa fa-lock`} />
                    ) : null;
                  },
                  maxWidth: 40,
                  sortable: true,
                  sortMethod: (a, b) => (a > b ? -1 : 1),
                  filterable: false,
                  filterMethod: (filter, row, column) => {
                    const id = filter.pivotId || filter.id;
                    const isLocked = row[id];
                    return (
                      (filter.value === 'true' && isLocked) ||
                      (filter.value === 'false' && !isLocked)
                    );
                  },
                };
                break;
              }

              default: {
                column = {
                  className: 'datatable-column__misc',
                  headerClassName: 'datatable-column__misc',
                  Header: makeTitle(item),
                  accessor: row => row[item] || dataPlaceholder,
                  id: item,
                };
              }
            }
          }

          // we go thru the columns and add in the widths we calculated
          // TODO: - this makes me sad. do better.

          if (column) {
            // page widths
            if (column.width || column.width === 0) {
              column.width = column.width; // explicits
            } else if (columnWidths[item] || columnWidths[item] === 0) {
              column.width = columnWidths[item]; // strings
            } else if (columnWidths[item.id] || columnWidths[item.id] === 0) {
              column.width = columnWidths[item.id]; // objects
            } else {
              column.width = columnWidths.avg; // fallback
            }

            // print widths
            if (column.printWidth || column.printWidth === 0) {
              column.printWidth = column.printWidth; // explicits
            } else if (
              columnPrintWidths[item] ||
              columnPrintWidths[item] === 0 // strings
            ) {
              column.printWidth = columnPrintWidths[item];
            } else if (
              columnPrintWidths[item.id] ||
              columnPrintWidths[item.id] === 0 // objects
            ) {
              column.printWidth = columnPrintWidths[item.id];
            } else {
              column.printWidth = columnPrintWidths.avg; // fallback
            }
          }

          return column;
        })
        .filter(item => item);

      return columns;
    }; 

    render() {
      return (
        <ComposedComponent
          {...this.state} // to make the pages rerender on changes
          {...this.props} // to make the pages rerender on changes
          makeColumns={this.makeColumns}
        />
      );
    }
  };
};

const mapStateToProps = state => {
  const { tableRef, marketplaceFeatures } = state.entities;
  return { tableRef, marketplaceFeatures };
};

export default compose(withRouter, connect(mapStateToProps, null), withTable);
